import React, { useEffect, useCallback } from 'react' // removed useState
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'
//
import Layout from '../../components/layout'
import Meta from '../../components/meta'
import { graphql } from 'gatsby'
import filterLocale from '../../components/utils/filter-locale'
import SupportSearch from '../../components/support/support-search'
// import AccordionComp from '../../components/support/accordion'
import Tabs from '../../components/support/tabs'
// import { Accordion } from '@reach/accordion'
import '@reach/accordion/styles.css'
// import useFaq from '../../hooks/useFaq'

const ContentsSection = tw.section`w-full bg-gray-200`
const ContentsContainer = tw.div`w-95 md:w-11/12 bg-white py-10 px-4 md:px-10 mx-auto border-l border-r border-gray-300 border-b rounded-b-lg mb-16`

// const TextsContainer = tw.div`flex flex-col mb-10`
// const FaqHeader = tw.h2`font-display text-3xl md:text-4xl font-bold mb-6`
// const FaqSubText = styled.p`
// 	${tw`font-display text-base md:text-lg`}

// 	span {
// 		${tw`underline text-blue-500`}
// 	}
// `
// const FaqsContainer = tw.div`border border-gray-300 bg-gray-100 py-6 md:py-10 px-4 xl:p-10`
// const FaqsInnerContainer = tw.div`w-full md:w-11/12 xl:w-9/12 mx-auto`

// const Acc = styled(Accordion)``

/**
 * Removed faqs
 * @param {object} supportfaq - page content
 * @param {object} faqs - faq array depreciated
 */
const Faq = ({ data: { supportfaq, faqs } }) => {
	const locale = 'en-AU'
	const [{ node }] = filterLocale(supportfaq.edges, locale)

	// const filterByLocale = (fq, locale) => {
	// 	return fq.edges.filter((x, i) => {
	// 		return x.node.node_locale === locale ? x : ''
	// 	})
	// }
	// const faqDetails = filterByLocale(faqs, locale)

	// const faqCats = [...faqs.distinct]
	// const res = faqs.distinct.map((x, i) => {
	// 	return {
	// 		category: x,
	// 		categoryDatas: [],
	// 		isOpen: false,
	// 	}
	// })

	// faqDetails.map(faq => {
	// 	const xx = faqCats.indexOf(faq.node.faqCategory)
	// 	if (xx > -1) {
	// 		res[xx].categoryDatas.push(faq)
	// 	}
	// })

	const { seoTitle, seoKeywords, seoDescription } = node

	// const [indices, setIndices] = useState([])
	// function toggleItem(toggledIndex) {
	// 	if (indices.includes(toggledIndex)) {
	// 		setIndices(indices.filter(currentIndex => currentIndex !== toggledIndex))
	// 	} else {
	// 		setIndices([...indices, toggledIndex].sort())
	// 	}
	// }

	// const { faqCat } = useFaq()
	// useEffect(() => {
	// 	const ind = res
	// 		.map((faq, i) => {
	// 			if (faq.category === faqCat.cat) {
	// 				return i
	// 			}
	// 		})
	// 		.filter(el => {
	// 			return el != undefined
	// 		})
	// 	setIndices(ind)
	// }, [faqCat])

	/**
	 * If FAQ element exists, initiate Smart-Tribune FAQ component
	 */
	const handleSTFAQInit = useCallback(event => {
		const exists = document.getElementById('st-faq')
		exists &&
			event.detail.init({
				kbId: 79,
				locale: 'en',
				cookieOptin: true,
				buildName: 'maytronics-aus',
				filters: { thematics: [], tags: [] },
			})
	}, [])

	useEffect(() => {
		window.addEventListener('STFAQLoaded', handleSTFAQInit)
		return () => {
			window.removeEventListener('STFAQLoaded', handleSTFAQInit)
		}
	}, [handleSTFAQInit])

	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
			>
				<script
					type="text/javascript"
					charset="UTF-8"
					src="https://assets.app.smart-tribune.com/maytronics/FAQ/faq.js"
					async
				></script>
				<link
					type="text/css"
					rel="stylesheet"
					charset="UTF-8"
					href="https://assets.app.smart-tribune.com/maytronics/FAQ/faq.css"
				></link>
			</Meta>
			<SupportSearch />
			<Tabs locale={locale} />
			<ContentsSection>
				<ContentsContainer>
					{/**
					 * Smart-Tribue FAQ component element
					 */}
					<div id="st-faq" />
					{/* <TextsContainer>
						<FaqHeader>Frequently Asked Questions & Troubleshooting</FaqHeader>
						<FaqSubText>
							Identify the fault and repair the robot myself.
						</FaqSubText>
					</TextsContainer>

					<FaqsContainer>
						<FaqsInnerContainer>
							<Acc index={indices} onChange={toggleItem}>
								{res.map((faq, i) => {
									return i === indices[0] ? (
										<AccordionComp
											key={i}
											item={faq}
											title={faqCat.title}
											condition={true}
										/>
									) : (
										<AccordionComp
											key={i}
											item={faq}
											title={faqCat.title}
											condition={false}
										/>
									)
								})}
							</Acc>
						</FaqsInnerContainer>
					</FaqsContainer> */}
				</ContentsContainer>
			</ContentsSection>
		</Layout>
	)
}

export const supportFaqQuery = graphql`
	query supportFaqQuery {
		supportfaq: allContentfulPage(
			filter: { contentful_id: { eq: "7vv29w2ZHiFKQccLfY2XVB" } }
		) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					node_locale
					slug
					body {
						json
					}
				}
			}
		}
	}
`

/**
 * Removed faq arry from query
 *
		faqs: allContentfulFaq(
			sort: { fields: product___createdAt, order: DESC }
			filter: { faq: { eq: true } }
		) {
			distinct(field: faqCategory)
			edges {
				node {
					title
					node_locale
					faqCategory
					slug
					description {
						description
					}
					content {
						json
					}
				}
			}
		}
 */

// Faq.propTypes = {
// 	data: PropTypes.objectOf({
// 		supportfaq: PropTypes.any,
// 		faqs: PropTypes.any,
// 	}),
// }

export default Faq
